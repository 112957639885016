import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import {Row, Col, Form, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalFooter, ModalBody, FormFeedback} from 'reactstrap'
import formStyles from '../components/styles/form.module.css'
import { handleRefer } from '../services/api';
import { isLoggedIn } from '../services/auth';
import ReCAPTCHA from "react-google-recaptcha";
import { PulseLoader } from 'react-spinners';
import { navigate } from 'gatsby'
import validator from 'validator'
import classnames from 'classnames'

const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

export default class ReferForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            name : "",
            referee: "",
            message: "",
            modal : false,
            loading : false,
            validateForm : false,
            captcha : null,
            isLoading : false,

            emailValid : false,
            nameValid : true,
            refereeValid : false,
            // messageValid : true,
        };
        this.modal = this.modal.bind(this)
    }

    componentDidMount(){
        this.setState({
            name: !user ? "" : user.userData.attributes.name,
        })
    }

    resetState() {
        this.setState({
            email: "",
            name: !user ? "" : user.userData.attributes.name,
            referee: "",
            message: "",
            modal : false,
            loading : false,
            validateForm : false,
            captcha : null,
            isLoading : false,

            emailValid : false,
            nameValid : true,
            refereeValid : false,
            // messageValid : true,
        })
    }

    modal() {
        this.setState({
            modal : !this.state.modal
        })
    }

    captchaChange = (value) => {
        console.log(value)
        const token = value

        this.setState({
            captcha : token
        }, () => {
            this.validateForm()
        })

    }

    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, () => {
            this.validateInput(name, value)
        })
    }


validateInput(field, value){ 
    let emailValid = this.state.emailValid
    let nameValid = this.state.nameValid
    let refereeValid = this.state.refereeValid
    // let messageValid = this.state.messageValid
    // let nameRegex = /^[A-Za-z0-9]*$/gm
    let emailRegex = /^\S+@\S+\.\S+$/
    let nameRegex = /^[A-Za-z0-9+\s]*$/gm

 
    if(value.length > 255 || value.length<1){
        return false
    }

    switch (field) {
        case 'email':
            emailValid =  !validator.isEmpty(value) && emailRegex.test(value)
            break;
    
        case 'name':
            nameValid = !validator.isEmpty(value) && nameRegex.test(value)
            break

        case 'referee' :
            refereeValid = !validator.isEmpty(value) && nameRegex.test(value) 
            break

        default:
            break;
    }

    this.setState({
        emailValid : emailValid,
        nameValid : nameValid,
        refereeValid : refereeValid,
        // messageValid : messageValid,
    }, () => {
        this.validateForm()
    })
}
    validateForm() {
        let formState = !!this.state.captcha && this.state.emailValid && this.state.nameValid && this.state.refereeValid //&& this.state.messageValid
        this.setState({
            validateForm : formState
        })
    }

    handleSubmit = async () => {
        
        this.setState({
            isLoading : true
        })
        await handleRefer(this.state)
        .then(()=>{
            this.modal()
        })
        console.log("done")
    }

    render() {
        return (
          <Row  className="row">
            <Col sm={{
              size:12
              }}
              md={{
                  size: 8,
                  offset: 2
              }}
            >
            <Form
                onSubmit={this.handleSubmit}
                className={[formStyles.referContainer].join(' ')}
            >
                <h3 style={{
                    fontWeight: 'bolder'
                }}>
                    Refer a Developer
                </h3>
                <p>Know anyone who is interested in integrating with NETS? <br/>Refer NETS to a developer!</p>

                <FormGroup>
                    <Label for="name">Your Name :</Label>
                    <Input
                        id="name"
                        autoFocus
                        type="text"
                        name="name"
                        title="Fill this field with your name"
                        style={!user ? null : { backgroundColor: `#e9ecef` }}
                        readOnly={!user ? null : true}
                        value={user ? user.userData.attributes.name : this.state.name.value}
                        onChange={this.handleChange}
                        className={classnames({ readOnly: !user ? false : true })}
                        invalid={!this.state.nameValid && !user ? true : undefined}
                        valid={this.state.nameValid && this.state.validateForm}
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="referee">Friend's Name* :</Label>
                    <Input
                        id="referee"
                        type="text"
                        title="Fill this field with your friend's name"
                        name="referee"
                        value={this.state.referee.value}
                        onChange={this.handleChange}
                        className={formStyles.signUp}
                        invalid={!this.state.refereeValid  && this.state.validateForm ? true : undefined}
                        valid={this.state.refereeValid && this.state.validateForm}
                        required
                    />
                    <FormFeedback invalid={!this.state.refereeValid ? '' : undefined}>
                        Please provide your friend's name
                    </FormFeedback>
                </FormGroup>

                <FormGroup>
                    <Label for="email">Friend's Email Address* :</Label>
                    <Input
                        id="email"
                        type="email"
                        name="email"
                        value={this.state.referee.value}
                        onChange={this.handleChange}
                        className={formStyles.signUp}
                        invalid={!this.state.emailValid && this.state.validateForm ? true : undefined}
                        valid={this.state.emailValid  && this.state.validateForm}
                        required
                    />
                    <FormFeedback invalid={!this.state.emailValid ? '' : undefined}>
                        Please provide a valid email address
                    </FormFeedback>
                </FormGroup>

                {!process.env.CAPTCHA_SITEKEY ? (
                    <font style={{color:`red`}}>
                        NOTE : This form is currently disabled as the reCaptcha Configuration is incomplete
                    </font>) : (
                    <FormGroup>
                        <ReCAPTCHA
                            id="captcha"
                            sitekey={process.env.CAPTCHA_SITEKEY}
                            onChange={this.captchaChange}
                        />
                    </FormGroup>
                )}

                <Button
                    block
                    color={!this.state.validateForm ? 'secondary' : this.state.isLoading ? 'secondary' : 'primary'}
                    disabled={!this.state.validateForm || this.state.isLoading}
                    onClick={ (event) => {
                        event.preventDefault()
                        this.handleSubmit()
                    }}
                >
                    {this.state.isLoading ? <PulseLoader color="white"/> : "Submit"}
                </Button>

                <Modal isOpen={this.state.modal} toggle={this.modal} className={this.props.className}>
                    <ModalHeader toggle={this.modal}>Success!</ModalHeader>
                    <ModalBody>
                        Thank you for your referral.
                    </ModalBody>
                    <ModalFooter>.
                        <Button
                            color="success"
                            onClick={() => {
                                this.modal()
                                navigate('/', {state : {data : "success"}})
                                this.resetState()
                            }}
                        >
                            Continue
                        </Button>{' '}
                    </ModalFooter>
                </Modal>
            </Form>
          </Col>
      </Row>
    )
  }
}
