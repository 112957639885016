import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../components/layout';
import ReferForm from '../containers/refer'
import { Container, UncontrolledAlert, Row, Col } from "reactstrap";

export default class refer extends Component {
  render() {
    return (
        <Layout>
          <Container
            style={{
              padding: '2rem 2rem',
              minHeight: '75vh'
            }}
          >
            {!this.props.location.state ? null : (
              <Row>
                <Col md={12}>
                  <UncontrolledAlert color="success" >
                    Thank you for your referral!
                  </UncontrolledAlert>
                </Col>
              </Row>
            )}

            <ReferForm />
          </Container>
        </Layout>
    )
  }
}
